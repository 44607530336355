import React from 'react';

// importing MyRouts where we located all of our theme
import MyRouts from './routers/routs';

function App() {
  return (
    <div>
      <MyRouts />
    </div>
  );
}

export default App;
